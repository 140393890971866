<template>
  <div>
    <div class="roofbox">
      <el-card class="management">
        <div class="management-card-head" slot="header">
          <p>底部设置</p>
        </div>
        <div class="bottom_wrap">
          <el-form :model="bottomForm" ref="bottomForm" label-width="160px">
            <el-form-item label="显示:">
              <div class="showQrcode d-flex align-items-center">
                <el-checkbox-group
                  v-model="checkList"
                  class="d-flex align-items-center"
                >
                  <div
                    class=""
                    v-for="(item, index) in bottomForm.qr_code_config"
                    :key="index"
                  >
                    <el-checkbox
                      :label="item.name"
                      :checked="item.selected"
                      @change="showCheck($event, index)"
                    ></el-checkbox>
                  </div>
                </el-checkbox-group>
              </div>
            </el-form-item>
            <template v-for="(item, index) in bottomForm.qr_code_config">
              <template v-if="item.selected">
                <el-form-item :label="item.name" :key="index">
                  <div class="">
                    <div
                      v-if="!item.url"
                      class="add_icon"
                      @click.stop="upQrcode(index, 'qr_code_config')"
                    >
                      <span>+</span>
                    </div>
                    <div
                      v-else
                      class="icon_img"
                      @click.stop="upQrcode(index, 'qr_code_config')"
                    >
                      <img :src="item.url" alt="" />
                    </div>
                  </div>
                </el-form-item>
              </template>
            </template>
            <el-form-item label="底部支持:">
              <div class="">
                <div
                  v-for="(item, index) in bottomForm.support"
                  :key="index"
                  class="support_item"
                >
                  <div class="support_left">
                    <div class="support_edit_img d-flex">
                      <div
                        class="upload_support_edit_img"
                        v-if="!item.url"
                        @click.stop="upQrcode(index, 'support')"
                      >
                        <i class="el-icon-plus"></i>
                      </div>
                      <div class="upload_support_img" v-else>
                        <div
                          class="upload_support_img_close"
                          @click="supportItemdel(index)"
                        >
                          <i class="el-icon-error"></i>
                        </div>
                        <div
                          class="upload_support_img_back"
                          :style="{ backgroundImage: `url(${item.url})` }"
                        ></div>
                        <div
                          class="upload_support_img_change"
                          @click.stop="upQrcode(index, 'support')"
                        >
                          替换
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="support_right">
                    <div class="support_right_title">
                      <span>主标题：</span>
                      <div class="support_right_input">
                        <el-input
                          type="text"
                          v-model="item.main_title"
                          show-word-limit
                          maxlength="10"
                        ></el-input>
                      </div>
                    </div>
                    <div class="support_right_title">
                      <span>副标题：</span>
                      <div class="support_right_input">
                        <el-input
                          type="text"
                          v-model="item.other_title"
                          show-word-limit
                          maxlength="10"
                        ></el-input>
                      </div>
                    </div>
                  </div>
                  <div class="support_close" @click="supportClose(index)">
                    <i class="el-icon-error"></i>
                  </div>
                </div>
                <div
                  class="support_item_toast"
                  v-if="bottomForm.support.length < 4"
                  @click="addSupport"
                >
                  +添加支持({{ bottomForm.support.length }}/4)
                </div>
              </div>
            </el-form-item>
            <el-form-item label="导航设置:">
              <div>
                <div
                  class="roof_manin"
                  v-for="(item, index) in bottomForm.roof"
                  :key="index"
                >
                  <div class="roof_input">
                    <el-input
                      v-model="item.title"
                      placeholder="标题"
                      show-word-limit
                      maxlength="8"
                    ></el-input>
                  </div>
                  <div class="roof_url">
                    <el-input
                      type="text"
                      v-model="item.url"
                      prefix-icon="el-icon-link"
                      placeholder="请选择链接"
                    >
                    </el-input>
                  </div>
                  <div class="roof_close" @click="delroofurl(index)">
                    <i class="el-icon-error"></i>
                  </div>
                </div>
              </div>
              <p v-if="bottomForm.roof.length < 10" class="support_item_toast">
                <a href="javascript:void(0);" @click="addnavigation">
                  +添加导航({{ bottomForm.roof.length }}/10)
                </a>
              </p>
            </el-form-item>
            <el-form-item label="资质展示:">
              <div>
                <div class="certification_toast">
                  （添加资质展示后，名称为必填项；链接或者图片只可添加或输入其中一项）
                </div>
                <div class="">
                  <div
                    v-for="(item, index) in bottomForm.intelligence"
                    :key="index"
                    class="competency"
                  >
                    <div class="competency_main">
                      <div class="">
                        <span>名称：</span>
                        <div class="inline-block">
                          <el-input
                            v-model="item.name"
                            maxlength="30"
                            show-word-limit
                          ></el-input>
                        </div>
                      </div>
                      <div>
                        <span>类型：</span>
                        <el-radio-group
                          v-model="item.type"
                          @change="intelligType($event, index)"
                        >
                          <el-radio :label="1">图片</el-radio>
                          <el-radio :label="2">链接</el-radio>
                        </el-radio-group>
                      </div>
                      <div v-if="item.type == 2">
                        <span>链接：</span>
                        <div class="inline-block">
                          <el-input
                            v-model="item.url"
                            show-word-limit
                          ></el-input>
                        </div>
                      </div>
                      <template v-else-if="item.type == 1">
                        <div>
                          <span>图片：</span>
                          <div
                            class="upload_support_edit_img"
                            v-if="!item.url"
                            @click.stop="upQrcode(index, 'intelligence')"
                          >
                            <i class="el-icon-plus"></i>
                          </div>
                          <div class="upload_support_img" v-else>
                            <div class="upload_support_img_close">
                              <i class="el-icon-error"></i>
                            </div>
                            <div
                              class="upload_support_img_back"
                              :style="{ backgroundImage: `url(${item.url})` }"
                            ></div>
                            <div
                              class="upload_support_img_change"
                              @click.stop="upQrcode(index, 'intelligence')"
                            >
                              替换
                            </div>
                          </div>
                        </div>
                      </template>
                    </div>
                    <div class="competency_close" @click="delnatural(index)">
                      <i class="el-icon-error"></i>
                    </div>
                  </div>
                </div>
                <p class="support_item_toast">
                  <a href="javascript:void(0);" @click="addaptitude">
                    +添加资质展示
                  </a>
                </p>
              </div>
            </el-form-item>
            <el-form-item label="底部版权:">
              <div>
                <Editor
                  v-model="bottomForm.editorcontent"
                  placeholder="底部版权"
                  height="350"
                  @change="gooddetail"
                  @uploadImgs="uploadImgs"
                />
              </div>
            </el-form-item>
            <el-form-item label="备案号:">
              <div class=" put_records">
                <div class="roof_input">
                  <el-input
                    v-model="bottomForm['pc.page.filing_info']"
                    placeholder="标题"
                    show-word-limit
                    maxlength="255"
                  ></el-input>
                </div>
                <div class="roof_url">
                  <el-input
                    type="text"
                    v-model="bottomForm['pc.page.filing_url']"
                    prefix-icon="el-icon-link"
                    placeholder="请选择链接"
                  >
                  </el-input>
                </div>
              </div>
            </el-form-item>
            <el-form-item label="联网备案:">
              <div class="networking_records">
                <div class="roof_input">
                  <el-input v-model="bottomForm['pc.page.filing_online']" placeholder="公网安备" show-word-limit maxlength="255"
                    @input="onlineInfo($event)"></el-input>
                </div>
              </div>
            </el-form-item>
            <el-form-item label="友情链接:">
              <div>
                <div>
                  <el-radio-group
                    v-model="bottomForm.friendRadio"
                    @change="friendChange"
                  >
                    <el-radio :label="1">文字+链接</el-radio>
                    <el-radio :label="2">图片+链接</el-radio>
                    <el-radio :label="3">文字图片+链接</el-radio>
                  </el-radio-group>
                </div>
                <div
                  v-for="(item, index) in bottomForm.friendList"
                  :key="index"
                  class="support_item"
                >
                  <div class="support_left">
                    <div class="support_edit_img d-flex">
                      <div
                        class="upload_support_edit_img"
                        v-if="!item.url"
                        @click.stop="upQrcode(index, 'friendList')"
                      >
                        <i class="el-icon-plus"></i>
                      </div>
                      <div class="upload_support_img" v-else>
                        <div class="upload_support_img_close">
                          <i class="el-icon-error"></i>
                        </div>
                        <div
                          class="upload_support_img_back"
                          :style="{ backgroundImage: `url(${item.url})` }"
                        ></div>
                        <div
                          class="upload_support_img_change"
                          @click.stop="upQrcode(index, 'friendList')"
                        >
                          替换
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="support_right">
                    <div class="support_right_title">
                      <span>名称：</span>
                      <div class="support_right_input">
                        <el-input
                          type="text"
                          v-model="item.name"
                          show-word-limit
                          maxlength="10"
                        ></el-input>
                      </div>
                    </div>
                    <div class="support_right_title">
                      <span>链接：</span>
                      <div class="support_right_input">
                        <el-input
                          type="text"
                          v-model="item.link"
                          show-word-limit
                          maxlength="255"
                        ></el-input>
                      </div>
                    </div>
                  </div>
                  <div class="support_close" @click="friendClose(index)">
                    <i class="el-icon-error"></i>
                  </div>
                </div>
                <p class="support_item_toast" @click="addFriend">
                  <a href="javascript:void(0);"> +添加友情链接 </a>
                </p>
              </div>
            </el-form-item>
          </el-form>
        </div>
      </el-card>
    </div>

        <diy-footer-button v-if="hasPerm(['pc.top.update'])">
          <el-button
            type="primary"
            @click.stop="preview"
            :loading="caijiLoading"
            >保存</el-button
          >
        </diy-footer-button>
    <linkUrl ref="selectLink" @change="linkGet"></linkUrl>
    <pictureSelect
      ref="pictureSelect"
      :multipled="is_multiple"
      @change="imgbrand($event)"
    ></pictureSelect>
  </div>
</template>
<script>
export default {
  data() {
    return {
      checkList: [],
      bottomForm: {
        qr_code_config: [],
        support: [],
        roof: [], //导航设置
        intelligence: [], //资质展示
        friendRadio: 1,
        friendList: [],
        editorcontent: "",
        "pc.page.filing_online":"",
      },
      trenchHedaline: 1,
      chnnerVisible: false,
      qrcodeIndex: 0,
      is_multiple: 0,
      qrcodeType: "",
      editorcontent: "",
      caijiLoading:false,
    };
  },
  created() {
    this.getBottom();
  },
  methods: {
    getBottom() {
      let that = this;
      this.$get(this.$apis.getPcPcBottom)
        .then((res) => {
          that.bottomForm.qr_code_config = res.data["pc.page.qr_code_config"];
          that.bottomForm.support = res.data["pc.page.bottom_setting"];
          that.bottomForm.roof = res.data["pc.page.navigation_setting"];
          that.bottomForm.intelligence = res.data["pc.page.credential"];
          that.bottomForm.friendList = res.data["pc.page.friendly_links"];
          that.bottomForm.editorcontent =
            res.data["pc.page.bottom_copyright_content"];
          that.bottomForm["pc.page.filing_info"] =
            res.data["pc.page.filing_info"];
          that.bottomForm["pc.page.filing_url"] =
            res.data["pc.page.filing_url"];
            that.bottomForm["pc.page.filing_online"]=res.data["pc.page.filing_online"]
        })
        .catch((err) => {
          //console.log(err);
          that.common.message(that, err.message);
        });
    },
    showCheck(value, index) {
      this.bottomForm.qr_code_config[index].selected =
        !this.bottomForm.qr_code_config[index].selected;
    },
    // 上传二维码
    upQrcode(index, type) {
      this.is_multiple = 0;
      this.qrcodeIndex = index;
      this.qrcodeType = type;
      this.$refs.pictureSelect.photoVisible =
        !this.$refs.pictureSelect.photoVisible;
    },
    roofLink(index, type) {
      this.qrcodeIndex = index;
      this.qrcodeType = type;
      this.$refs.selectLink.linkVisible = !this.$refs.selectLink.linkVisible;
    },
    linkGet(value) {
      if (this.qrcodeType == "friendList") {
        this.bottomForm["" + this.qrcodeType][this.qrcodeIndex].link =
          value.link;
      } else {
        this.bottomForm["" + this.qrcodeType][this.qrcodeIndex].url =
          value.link;
      }
    },
    imgbrand(value) {
      if (this.qrcodeType == "editorcontent") {
        for (let i = 0; i < value.length; i++) {
          this.bottomForm.editorcontent += `<p><img src="${value[i].file_url}" style="max-width:100%;"/></p>`;
        }
      } else {
        this.bottomForm["" + this.qrcodeType][this.qrcodeIndex].url =
          value[0].file_url;
      }
    },
    intelligType(value, index) {
      this.bottomForm.intelligence[index].url = "";
    },
    friendChange(value) {},
    // 底部支持
    // 删除图片
    supportItemdel(index) {
      this.bottomForm.support[index].url = "";
    },
    // 删除模块
    supportClose(index) {
      this.bottomForm.support.splice(index, 1);
    },
    // 删除模块
    addSupport() {
      let obj = {
        url: "",
        main_title: "",
        other_title: "",
      };
      this.bottomForm.support.push(obj);
    },
    // 导航设置
    addnavigation() {
      let obj = {
        url: "",
        title: "",
      };
      this.bottomForm.roof.push(obj);
    },
    delroofurl(index) {
      this.bottomForm.roof.splice(index, 1);
    },
    trenchTop(index) {
      this.trenchHedaline = index;
    },
    ditchTrench(index, inx) {
      this.trenchPage = index;
      this.ditchPage = inx;
    },
    // 添加资质展示
    addaptitude() {
      let obj = {
        name: "",
        type: 1,
        url: "",
      };
      this.bottomForm.intelligence.push(obj);
    },
    delnatural(index) {
      this.bottomForm.intelligence.splice(index, 1);
    },
    addFriend() {
      let obj = {
        url: "",
        type: this.bottomForm.friendRadio,
        name: "",
        link: "",
      };
      this.bottomForm.friendList.push(obj);
    },
    preview() {
      this.caijiLoading=true;
      let that = this;
      this.$put(this.$apis.getPcPcBottom, {
        "pc.page.bottom_copyright_content": this.bottomForm.editorcontent,
        "pc.page.bottom_setting": this.bottomForm.support,
        "pc.page.qr_code_config": that.bottomForm.qr_code_config,
        "pc.page.navigation_setting": that.bottomForm.roof,
        "pc.page.credential": that.bottomForm.intelligence,
        "pc.page.friendly_links": that.bottomForm.friendList,
        "pc.page.bottom_copyright_content": that.bottomForm.editorcontent,
        "pc.page.filing_info": that.bottomForm["pc.page.filing_info"],
        "pc.page.filing_url": that.bottomForm["pc.page.filing_url"],
        "pc.page.filing_online":that.bottomForm["pc.page.filing_online"],
      })
        .then((res) => {
          that.caijiLoading=false;
          if (res.code == 200) {
            that.$message({
              type: "success",
              message: "保存成功",
              showClose: true,
              offset: 200,
              duration: 1000,
            });
          } else {
            that.common.message(that, res.message);
          }
        })
        .catch((err) => {
          that.caijiLoading=false;
          that.common.message(that, err.message);
        });
    },
    gooddetail(val) {
      this.bottomForm.editorcontent = val;
    },
    uploadImgs() {
      this.is_multiple = 1;
      this.qrcodeType = "editorcontent";
      this.$refs.pictureSelect.photoVisible =
        !this.$refs.pictureSelect.photoVisible;
    },
    onlineInfo(e){
      this.$set(this.bottomForm, 'pc.page.filing_online', e);
      this.bottomForm['pc.page.filing_online']=e;
    },
  },
};
</script>
<style lang="scss" scoped>
.roofbox {
  background: #fff;
  border-radius: 4px;
  position: relative;
  // margin-bottom: 80px;
  transition: all 0.2s ease-in-out;
}
.management {
  flex: 1;
}
.roof_input .el-input {
  width: 180px;
}
.management-card-head {
  // border-bottom: 1px solid #f2f2f2;
  // padding: 8px 20px;
  line-height: 1;
}
.management-card-head p {
  display: inline-block;
  width: auto;
  line-height: 28px;
  font-size: 14px;
  color: #333;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: 700;
}
/* 域名配置的弹窗 */
.domain_wrap {
  padding: 16px 20px;
}
.domain_table {
  margin-top: 10px;
}

.domain_table table th,
table td {
  border: none;
}

.domain_table
  .el-table
  .el-table__header-wrapper
  .el-table__header
  thead
  tr
  th {
  background-color: #f9f9fc;
}

.domain_table .el-table .el-table__header-wrapper {
  background-color: #f9f9fc;
}

.domain_table .el-table .el-table__fixed .el-table__fixed-header-wrapper {
  background-color: #f9f9fc;
}

.el-table th.el-table__cell {
  background-color: #f9f9fc;
  font-weight: 600;
  color: #333;
  padding: 8px 0;
}
.caozuo {
  color: var(--fontColor);
  cursor: pointer;
}
.caozuo a {
  padding: 0 10px;
  border-right: 1px solid var(--fontColor, #fb6638);
  cursor: pointer;
  display: inline-block;
  line-height: 1;
  color: var(--fontColor);
}

.caozuo a:last-child {
  border-right: none;
}
.empty_data {
  width: 100%;
  padding: 10px 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.empty_data p {
  margin-top: 16px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.4);
  line-height: 20px;
}
/* 添加域名的弹窗 */
.addDoName {
  padding: 16px 20px;
}
.addDoName_input_label {
  text-align: right;
  vertical-align: middle;
  float: left;
  font-size: 14px;
  color: #666;
  line-height: 1;
  padding: 10px 20px 10px 0;
  box-sizing: border-box;
  width: 60px;
}
.addDoName_input_label::before {
  content: "*";
  display: inline-block;
  margin-right: 4px;
  line-height: 1;
  font-family: SimSun;
  font-size: 12px;
  color: #f1495c;
}
.addDoName_input .el-input {
  flex: 1;
}
.management_foot {
  position: fixed;
  left: 240px;
  right: 0;
  bottom: 0;
  min-width: 980px;
  margin: 0 24px;
  height: auto;
  border-top: 1px solid #f1f1f1;
  z-index: 100;
  background: #fff;
  border-radius: 4px;
  transition: all 0.2s ease-in-out;
}
.management_foot_body {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
}
/* 底部设置 */
.bottom_wrap {
  padding: 20px;
}
.support_item {
  width: 428px;
  /* height: 120px; */
  display: flex;
  align-items: center;
  background: #f4f3f7;
  margin-bottom: 8px;
  padding: 20px;
  position: relative;
}

.upload_support_edit_img {
  width: 80px;
  height: 80px;
  margin-right: 10px;
  border: 1px dashed #ededed;
  text-align: center;
  color: #dad9d9;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  float: left;
  position: relative;
  background: white;
  line-height: 80px;
  cursor: pointer;
}
.upload_support_edit_img i {
  font-size: 50px;
  vertical-align: middle;
}
.upload_support_img {
  width: 80px;
  height: 80px;
  margin-right: 10px;
  text-align: center;
  color: #dad9d9;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  float: left;
  position: relative;
  border: 1px solid #ededed;
  cursor: pointer;
}
.upload_support_img_close {
  display: none;
  position: absolute;
  top: -8px;
  right: -8px;
  line-height: 1;
  z-index: 1;
  color: #999;
}
.upload_support_img:hover .upload_support_img_close {
  display: block;
}
.upload_support_img_back {
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: contain;
  width: 100%;
  height: 100%;
}
.upload_support_img_change {
  display: none;
  height: 20px;
  line-height: 20px;
  width: 100%;
  position: absolute;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
}
.upload_support_img:hover .upload_support_img_change {
  display: block;
}

.support_right {
  flex: 1;
  margin-left: 10px;
}
.support_right_title {
  margin-bottom: 10px;
  display: flex;
}
.support_right_input {
  display: inline-block;
  flex: 1;
}
.support_close {
  visibility: hidden;
  position: absolute;
  right: -6px;
  top: -15px;
  cursor: pointer;
}
.support_item:hover .support_close {
  visibility: visible;
}
.support_item_toast {
  color: var(--fontColor, #fb6638);
  cursor: pointer;
}
.roof_close {
  visibility: hidden;
  position: absolute;
  right: -8px;
  top: -14px;
  cursor: pointer;
}
.roof_manin:hover .roof_close {
  visibility: visible;
}
.roof_close i {
  font-size: 16px;
}
.competency_close {
  visibility: hidden;
  position: absolute;
  right: -8px;
  top: -12px;
  cursor: pointer;
}
.competency:hover .competency_close {
  visibility: visible;
}
.bottom_wrap {
  padding: 20px;
}
.support_item {
  width: 428px;
  /* height: 120px; */
  display: flex;
  align-items: center;
  background: #f4f3f7;
  margin-bottom: 8px;
  padding: 20px;
  position: relative;
}

.upload_support_edit_img {
  width: 80px;
  height: 80px;
  margin-right: 10px;
  border: 1px dashed #ededed;
  text-align: center;
  color: #dad9d9;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  float: left;
  position: relative;
  background: white;
  line-height: 80px;
  cursor: pointer;
}
.upload_support_edit_img i {
  font-size: 50px;
  vertical-align: middle;
}
.upload_support_img {
  width: 80px;
  height: 80px;
  margin-right: 10px;
  text-align: center;
  color: #dad9d9;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  float: left;
  position: relative;
  border: 1px solid #ededed;
  cursor: pointer;
}
.upload_support_img_close {
  display: none;
  position: absolute;
  top: -8px;
  right: -8px;
  line-height: 1;
  z-index: 1;
  color: #999;
}
.upload_support_img:hover .upload_support_img_close {
  display: block;
}
.upload_support_img_back {
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: contain;
  width: 100%;
  height: 100%;
}
.upload_support_img_change {
  display: none;
  height: 20px;
  line-height: 20px;
  width: 100%;
  position: absolute;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
}
.upload_support_img:hover .upload_support_img_change {
  display: block;
}

.support_right {
  flex: 1;
  margin-left: 10px;
}
.support_right_title {
  margin-bottom: 10px;
  display: flex;
}
.support_right_input {
  display: inline-block;
  flex: 1;
  /deep/.el-input{
    width: 100%;
  }
}
.support_close {
  visibility: hidden;
  position: absolute;
  right: -6px;
  top: -15px;
  cursor: pointer;
}
.support_item:hover .support_close {
  visibility: visible;
}
.support_item_toast {
  color: var(--fontColor, #fb6638);
  cursor: pointer;
}
.competency {
  margin-top: 10px;
  height: 205px;
  width: 628px;
  display: flex;
  background: #f4f3f7;
  margin-bottom: 8px;
  padding: 20px;
  position: relative;
}
.competency_main {
  flex: 1;
  margin-left: 10px;
}
.competency_main > div {
  margin-bottom: 10px;
  display: flex;
}
.competency_main > div > span {
  display: inline-block;
  width: 60px;
}
.competency_main > div > .inline-block {
  flex: 1;
}

.trench {
  padding: 16px 20px;
  font-size: 12px;
  line-height: 1.5;
  color: #666;
  max-height: 500px;
  min-height: 108px;
  overflow-y: auto;
  max-height: 620px;
  overflow: visible;
}
.trench_wrap {
  height: 500px;
  position: relative;
}
.trench_head {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 30px;
}
.trench_head .trench_head_btn {
  float: left;
  border-radius: 4px;
  margin-right: 10px;
  width: 88px;
  height: 36px;
  line-height: 36px;
  text-align: center;
  border: 1px solid #f1f1f1;
  color: #666;
  cursor: pointer;
}
.trench_head .trench_head_btn.active {
  border: none;
  background-color: var(--fontColor, #fb6638);
  color: #fff;
}
.trench_left {
  top: 45px;
  width: 160px;
  height: 455px;
  color: #333;
  float: left;
  position: relative;
  border: 1px solid #f1f1f1;
  overflow: auto;
  overflow-x: hidden;
  border-radius: 4px;
  padding: 10px 0 10px 0;
}
.dividegoods {
  height: 36px;
  line-height: 36px;
  width: 100%;
  border-radius: 4px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
  padding: 0 25px;
  position: relative;
}
.trench_left .dividegoods.active {
  color: var(--fontColor, #fb6638);
  background: var(--primary-tips, #fff3ef);
}
.trench_right {
  position: absolute;
  top: 45px;
  left: 170px;
  right: 0;
  bottom: 0;
  border: 1px solid #f1f1f1;
  overflow: auto;
  overflow-x: hidden;
  border-radius: 4px;
  padding: 10px;
}
.trench_page_box .url_page_title {
  line-height: 43px;
  height: 43px;
}
.trench_page_list {
  overflow: hidden;
}
.trench_page_list > div {
  float: left;
  height: 32px;
  line-height: 32px;
  padding: 0 16px;
  border: 1px solid #ededed;
  border-radius: 4px;
  margin: 5px 9px 5px 0;
  cursor: pointer;
  position: relative;
}
.trench_page_list > div.active {
  border-color: var(--fontColor, #fb6638);
}
.trench_page_list > div p {
  max-width: 130px;
}
.trench_page_list > div i {
  position: absolute;
  right: -11px;
  bottom: -10px;
  color: #fff;
  font-size: 21px;
  z-index: 50;
  visibility: hidden;
  transform: scale(0.7);
}
.trench_page_list > div i.active {
  color: var(--fontColor);
  visibility: visible;
}
.roof_wrap {
  padding: 20px;
}
.put_records{
  position: relative;
  width: 600px;
  display: flex;
  background: #f4f3f7;
  margin-bottom: 8px;
  padding: 20px;
  .roof_input{
    /deep/.el-input{
      width: 280px;
    }
  }
  
}

.networking_records{
  width: 600px;
  display: flex;
  background: #f4f3f7;
  margin-bottom: 8px;
  padding: 20px;
  .roof_input {
    width: 100%;
    ::v-deep .el-input {
      width: 100%;
    }
  }
}

.roof_manin {
  position: relative;
  width: 428px;
  display: flex;
  background: #f4f3f7;
  margin-bottom: 8px;
  padding: 20px;
}
.roof_input .el-input {
  width: 180px;
}
.roof_url {
  flex: 1 1 0%;
  width: 100%;
  height: 36px;
  line-height: 36px;
  // padding: 0 10px;
  background: #e8effc;
  border-radius: 4px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
  color: #666;
  overflow: hidden;
  word-break: break-all;
  text-overflow: ellipsis;
  position: relative;
  // padding-right: 22px;
  margin-left: 10px;
  font-size: 14px;
  /deep/.el-input {
    height: 36px;
    line-height: 36px;
    background: #e8effc;
    cursor: pointer;
    width: 100%;
    .el-input__inner {
      height: 36px;
      line-height: 36px;
      background: #e8effc;
      cursor: pointer;
    }
  }
}
.roof_url .roof_url_link {
  font-size: 14px;
  margin-right: 3px;
}
.roof_url .roof_url_close {
  font-size: 25px;
}
.roof_close {
  visibility: hidden;
  position: absolute;
  right: -8px;
  top: -8px;
  cursor: pointer;
}
.roof_manin:hover .roof_close {
  visibility: visible;
}
.roof_close i {
  font-size: 16px;
}
.add_icon {
  width: 78px;
  height: 78px;
  background: #ffffff;
  border-radius: 2px;
  border: 1px solid #ededed;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  span {
    font-size: 38px;
    // background: #dad9d9;
  }
}
.icon_img {
  width: 78px;
  height: 78px;
  cursor: pointer;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.showQrcode {
  /deep/.el-checkbox-group {
    > div {
      margin-left: 20px;
    }
  }
}
</style>