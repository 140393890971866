import { render, staticRenderFns } from "./bottomBase.vue?vue&type=template&id=bed4a5c2&scoped=true&"
import script from "./bottomBase.vue?vue&type=script&lang=js&"
export * from "./bottomBase.vue?vue&type=script&lang=js&"
import style0 from "./bottomBase.vue?vue&type=style&index=0&id=bed4a5c2&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bed4a5c2",
  null
  
)

export default component.exports